// Header.js
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import MenuBars from "../../assets/imgs/menu-bars.svg";
import Menu from "./Menu";
import Logo from "../../assets/imgs/logo.svg";
import "./Header.css";
import menuItems from './Btns-Content';
import MenuBtn from './MenuBtn';

const Header = ({ scrollToComponent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (
      <div key={index}>
        <MenuBtn action={action} data={menuItem} scrollToComponent={scrollToComponent} />
      </div>
    ));
  }, [menuItems]);

  const main = process.env.REACT_APP_MAIN;

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`main-header ${isScrolled ? 'scrolled' : ''} header-wrapper`}
    >
      <div className="header-container header-body-footer-width">
        <div className="header-img-div">
          <div className="logo-link header-img">
            <Link to={main} onClick={scrollUp}>
              <img
                className="logo-link header-img"
                src={Logo}
                alt="logo imagem"
              ></img>
            </Link>
          </div>
        </div>
        <nav>
          {memoizedMenuItems}
        {/*   <a className="main-btn-green" href="https://wa.me/5584986078499" target="_blank" rel="noreferrer">Contato</a> */}


          {/* <div className="header-socials">

            <a
              href="https://www.linkedin.com/company/gb-advocacia-criminal/"
              aria-label="Linkedin icon"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa fa-linkedin"></i>
            </a>
            <a
              href="https://www.instagram.com/gbadvocaciacriminal/"
              aria-label="Instagram icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>

            <a
              href="            https://wa.me/message/IMG5YFUHUYM5D1"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-whatsapp"></i>
            </a>

            <a
              href="https://www.facebook.com/gbadvocaciacriminal"
              target="_blank"
              rel="noreferrer"
              aria-label="Facebook icon"
            >
              <i className="fa fa-facebook"></i>
            </a>
          </div> */}

        </nav>

        <button className="menu-toggle" onClick={toggleSidebar}>
          <img src={MenuBars} alt="Menu Bars" />
        </button>
      </div>

      <Menu
        scrollToComponent={scrollToComponent}
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default Header;
