import React from "react";
import "./PorqueVocePrecisa.css";
import Grid from "../../assets/imgs/SL-072622-51930-14.jpg";

function PorQueSiteProfissional() {
  return (
    <div className="porque-precisa-container">
      <div className="shape-wrapper">
        <h2>
          Por que ter a <span style={{ color: "#F25C05" }}>Segura RN</span> com
          você?
        </h2>

        <div className="porque-precisa-content">
          <ul>
            <div>
              <li>
                <strong className="font">Expertise</strong>
                Com anos de experiência e uma equipe altamente qualificada,
                garantimos eficácia nas soluções para os seus problemas.
              </li>
              <li>
                <strong className="font">Soluções Completas</strong> Oferecemos serviços completos em tecnologia, segurança e monitoramento para uma proteção abrangente, desde a instalação até a manutenção contínua.
              </li>
              <li>
                <strong className="font">Proteção</strong> Com a Segura, seu patrimônio está protegido por uma equipe dedicada, pronta para agir rapidamente em qualquer situação, garantindo sua segurança total.
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PorQueSiteProfissional;
