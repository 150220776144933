import React from 'react';
import './CTA.css';

function CTA() {
  return (
    <div className='cta-container'>
      <div class="cta-content">
        <h2>Se interessou por algum serviço?</h2>
        <p>Faça um orçamento consco</p>
        <a href="https://wa.me/5584986078499" target="_blank" rel="noreferrer" className='main-btn'>Entre em Contato</a>

      </div>
    </div>
  );
}

export default CTA;
