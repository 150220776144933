// Menu.js
import React, { useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./Menu.css";
import menuItems from "./Btns-Content";
import MenuBtn from "./MenuBtn";

import Close from "../../assets/imgs/close.svg";

const Menu = ({ toggleSidebar, isOpen, setIsOpen, scrollToComponent }) => {
  const sidebarRef = useRef(null);

  const closeSidebar = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const action = (address) => {
    toggleSidebar();
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeSidebar);
    return () => {
      document.removeEventListener("mousedown", closeSidebar);
    };
  });

  const main = "/gb-principal/";

  return (
    <>
      <CSSTransition in={isOpen} timeout={500} classNames="fade" unmountOnExit>
        <div>
          <div className="sidebar" ref={sidebarRef}>
            <button
              className="menu-toggle close-menu-btn"
              onClick={toggleSidebar}
            >
              <img src={Close} alt="Close" />
            </button>

            <div className="menu-btns">
              {menuItems.map((menuItem, index) => (
                <div key={index}>
                  <MenuBtn data={menuItem} action={action} />
                </div>
              ))}
            </div>

            <div className="sidebar-socials">
              <p className="cta">Siga nossas redes sociais</p>
              <div className="sidebar-socials-content">
                <a
                  href="https://www.instagram.com/segura.rn/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="uil uil-instagram"></i>
                </a>

                <a
                  href="https://wa.me/5584986078499"
                  aria-label="Whatsapp Icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="uil uil-whatsapp"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="curtain"></div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Menu;
