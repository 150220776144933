import React from 'react';
import '../sobre/Sobre.css';
import Perfil from '../../assets/imgs/logofofa.png';
import './Tornamos.css';

function TornamosSimples() {
  return (
    <div class="sobre-wrapper tornamos-wrapper">

      <div className="shape-small-1"></div>

      <section className='sobre-container'>
        <div className='sobre-content'>
          <h2>Nos siga nas redes sociais</h2>
          <h4>Junte-se a nós nas redes sociais para se manter atualizado com todas as novidades!</h4>          
          <div className='bullets'>
            <div>
              <i class="uil uil-check"></i> <p>Acompanhementoem tempo real</p>
            </div>

            <div>
              <i class="uil uil-check"></i> <p>Atendimento rápido</p>
            </div>

           

          
          </div>
           <div className="sidebar-socials-content">
                <a
                  href="https://www.instagram.com/segura.rn/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="uil uil-instagram"> @segura.rn</i>
                </a>

                <a
                  href="https://wa.me/5584986078499"
                  aria-label="Whatsapp Icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="uil uil-whatsapp">(84)98607-8499</i>
                </a>
              </div>
          
        </div>
        <div className='sobre-img-container'>
          <img src={Perfil} alt='monitor img'></img>
        </div>
      </section>
    </div>
  );
}

export default TornamosSimples;
