import React from "react";
import '../depoimentos/Depoimentos.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Test1 from '../../assets/imgs/6.webp';
import Test3 from '../../assets/imgs/2.webp';
import Test4 from '../../assets/imgs/3.webp';
import Test5 from '../../assets/imgs/4.webp';
import Test7 from '../../assets/imgs/5.webp';
import Test9 from '../../assets/imgs/1.webp';
import Test10 from '../../assets/imgs/7.webp';

const Testimonials = () => {
  const settings = {
    arrows: false,
    dots: false,
    pauseOnHover: false,
    infinite: true,
    speed: 15000,
    autoplaySpeed: 15000,
    cssEase: "linear",
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };


  return (
    <div className="testimonials-wrapper">

      <div className="testimonials-container">
<h2>Veja nosso trabalho na prática</h2>
        <Slider {...settings} >
          <div className="testimonials-item">
            <img src={Test1} alt="imagem de depoimentos"></img>
          </div>
          {/* <div className="testimonials-item">
            <img src={Test2} alt="imagem de depoimentos"></img>
          </div> */}
          <div className="testimonials-item">
            <img src={Test3} alt="imagem de depoimentos"></img>
          </div>
          <div className="testimonials-item">
            <img src={Test4} alt="imagem de depoimentos"></img>
          </div>
          <div className="testimonials-item">
            <img src={Test5} alt="imagem de depoimentos"></img>
          </div>
          {/* <div className="testimonials-item">
            <img src={Test6} alt="imagem de depoimentos"></img>
          </div> */}
          <div className="testimonials-item">
            <img src={Test7} alt="imagem de depoimentos"></img>
          </div>
          {/* <div className="testimonials-item">
            <img src={Test8} alt="imagem de depoimentos"></img>
          </div> */}
          <div className="testimonials-item">
            <img src={Test9} alt="imagem de depoimentos"></img>
          </div>
          <div className="testimonials-item">
            <img src={Test10} alt="imagem de depoimentos"></img>
          </div>

        </Slider>
      </div>

    </div>
  );
};

export default Testimonials;
