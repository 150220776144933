import React from "react";
import "./Home.css";
import Perfil from "../../assets/imgs/mainimg.jpeg";

function Home() {
  return (
    <div className="home-wrapper">
      <div className="shapes-wrapper">
        <div className="shape-small"></div>
        <div className="shape-small-1"></div>

   {/*      <div className="shape-small-2"></div> */}
        <div className="home-container">
          <div className="home-content">
            <h1>
              Promovemos <span style={{ color: "#F25C05" }}>Segurança</span> de Alto Padrão</h1>
            <p>
            Oferecemos soluções abrangentes em tecnologia, segurança e monitoramento para proteger seu espaço. Com nossa expertise em sistemas avançados, garantimos sua tranquilidade e protegemos o que é mais importante para você.
            </p>

            <a className="main-btn " href="https://wa.me/5584986078499" target="_blank" rel="noreferrer">Entre em contato</a>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Home;
