import React from 'react';
import './Servicos.css'; // Estilo CSS para a seção
import Coroa from '../../assets/imgs/Crown.svg'

function ServicesSection() {
  return (
    <section className="services-section">
      <div className="maisprocurados-title">
          <h2>Nossos Serviços</h2>
          <img className='img-coroa' src={Coroa} alt="" />
      </div>

      
      <div className="services-container">
        <div className="service alt-2">
          <h3 className='h3-diferente'>Portaria Remota</h3>
          <p>Com nossa solução de portaria remota, garantimos segurança e controle de acesso contínuos, proporcionando tranquilidade constante e redução significativa nos custos operacionais.</p>
        </div>

        <div className="service">
          <h3 className='h3-diferente'>
            Controle de Acesso SEGURA</h3>
          <p>Garantimos a tranquilidade e segurança dos moradores restringindo a entrada de pessoas não autorizadas e permitindo que os moradores tenham total controle sobre quem entra e quem sai proporcionando um ambiente seguro e confortável aos residentes.</p>
        </div>

        <div className="service">
        <h3 className='h3-diferente'>Alarme monitorado</h3>
          <p>Deixe tudo seguro com nossa proteção 24 horas por dia com nosso serviço de alarme monitorado.</p>
        </div>

        <div className="service alt-1">
          <h3 className='h3-diferente'>Fechadura digital</h3>
          <p>Aumente a segurança e modernize seu acesso com nossas fechaduras digitais, oferecendo conveniência e controle total sobre quem entra em seus espaços.</p>
        </div>

        <div className="service alt-1">
          <h3 className='h3-diferente'>Alarme residencial/empresarial</h3>
          <p>Mantenha-se alerta e protegido com nossos alarmes residenciais/empresariais, oferecendo detecção precoce e resposta rápida a qualquer ameaça.</p>
        </div>


        <div className="service alt-2">
          <h3 className='h3-diferente'>Câmeras de segurança</h3>
          <p>Nossas câmeras de segurança proporcionam vigilância 24 horas por dia, 7 dias por semana, para uma proteção completa e confiável de seu espaço.</p>
        </div>
  

        <div className="service alt-2">
          <h3 className='h3-diferente'>Cerca elétrica </h3>
          <p>Proteja sua propriedade com nossa cerca elétrica, uma barreira eficaz que dissuade intrusos e proporciona tranquilidade.</p>
        </div>
        <div className="shape-small-2"></div>

        <div className="service alt-2">
          <h3 className='h3-diferente'>Cerca concertina</h3>
          <p>Com a cerca concertina, proporcionamos uma camada adicional de segurança, tornando seu perímetro praticamente intransponível.</p>
        </div>
     

        <div className="service alt-2">
          <h3 className='h3-diferente'>CFTV= Circuito Fechado de Televisão (Câmeras)</h3>
          <p>Oferecemos soluções de vigilância precisa e contínua com nossos sistemas de CFTV, garantindo a segurança de seus espaços com tecnologia avançada de câmeras.</p>
        </div>
        <div className="service alt-2">
          <h3 className='h3-diferente'>Motor para Portão</h3>
          <p>Oferecemos soluções de vigilância precisa e contínua com nossos sistemas de CFTV, garantindo a segurança de seus espaços com tecnologia avançada de câmeras.</p>
        </div>
        <div className="service alt-2">
          <h3 className='h3-diferente'>Venda, Instalação e Comodato de Equipamentos de Segurança.</h3>
          <p>Oferecemos uma variedade de opções em venda, instalação e comodato de equipamentos de segurança, adaptando-se às suas necessidades e orçamento.</p>
        </div>
        <div className="service alt-2">
          <h3 className='h3-diferente'>Equipe de instalação e manutenção em equipamentos de segurança eletrônica</h3>
          <p>Conte com nossa equipe especializada em instalação e manutenção de equipamentos de segurança eletrônica, garantindo um funcionamento perfeito e duradouro de seus sistemas.</p>
        </div>
       
    
      </div>

    </section>
  );
}

export default ServicesSection;