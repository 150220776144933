import "./Footer.css";
import React, { useMemo } from "react";
import Logo from "../../assets/imgs/logo.svg";
import menuItems from './Btns-Content';
import MenuBtn from './MenuBtn';
import Demo from '../newsletter/Demo';

const Footer = ({ scrollToComponent }) => {

  const mensagemsite = "Olá! Gostei do desenvolvimento do seu site, gostaria de saber mais.";

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (

      <MenuBtn className="footer-menu-btn" action={action} data={menuItem} scrollToComponent={scrollToComponent} />

    ));
  }, [menuItems]);

  return (
    <footer className="footer-background">
      <div className="footer-container header-body-footer-width">
        <div class="footer-container-division">

          <div className="footer-wrap footer-content">
            <div className="footer-logo">
              <img className="footer-img" src={Logo} alt="logo"></img>
            </div>
            <div className="footer-menu-container">
              <div className="footer-menu">
                <h5>NAVEGAÇÃO</h5>
                <div>
                  {memoizedMenuItems}
                </div>
              </div>
              <div className="footer-menu">
                <h5>ESPECIALIDADES</h5>
                <div>
                  <p>Portaria Remota</p>
                  <p>Controle de Acesso</p>
                  <p>Monitoramento de Alarme 24 horas</p>
                  <p>CFTV= Circuito Fechado de Televisão (Câmeras)</p>
                  <p>Cerca Elétrica </p>
                  <p>Motor para Portão </p>
                  <p>Alarme Residencial/Empresarial</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrap footer-bottom-container">
          <div className="footer-copy">
            <p>&copy; Segura RN.</p>
            <a className="main-btn2" href={`https://wa.me/5584996060201?text=${encodeURIComponent(mensagemsite)}`}
            target="_blank"
            rel="noreferrer">Desenvolvido por <span className="testefooter" style={{ color: "#F25C05" }}>Luana Salmito</span> </a>
          </div>
         
        </div>
      </div>
    </footer>
  );
};

export default Footer;
