import React, { useState } from 'react';
import './FAQ.css';
import faqData from './faqData.json';

function FAQ() {
  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const toggleItem = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((item) => item !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  return (
    <div className='faq-container'>
      <h2>Perguntas Frequentes</h2>
      {faqData.map((item, index) => (
        <div key={index} className='faq-item'>
          <div className='faq-question'>
            <button onClick={() => toggleItem(index)}>
            <p className='pergunta'>{item.pergunta}</p>
              <span>{expandedIndexes.includes(index) ? '-' : '+'}</span>
            </button>
          </div>
          {expandedIndexes.includes(index) && <p>{item.resposta}</p>}
        </div>
      ))}
    </div>
  );
}

export default FAQ;
