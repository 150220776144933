const type = "Button";

const menuItems = [
    {
        label: "Início",
        type: type,
        to: "inicio",
    },
    {
        label: "Serviços",
        type: type,
        to: "servicos",

    },
    {
        label: "Nosso Trabalho",
        type: type,
        to: "nossotrabalho",
    },
    {
        label: "Contato",
        type: type,
        to: "contato",
    }
];

export default menuItems;
